export function useAuth() {
  const { $pinia } = useNuxtApp();

  const authStore = useAuthStore($pinia);
  const onboardingStore = useOnboardingStore($pinia);
  const discountStore = useDiscountStore($pinia);
  const checkoutStore = useCheckoutStore($pinia);
  const biTrackingStore = useBiTrackingStore($pinia);
  const userStore = useUserStore($pinia);
  const tokenUserStore = useTokenUserStore($pinia);

  function logout() {
    authStore.$reset();
    userStore.$reset();
    onboardingStore.$reset("logout");
    discountStore.$reset();
    checkoutStore.$reset();
    biTrackingStore.$reset();
    tokenUserStore.$reset();
  }

  return { logout };
}
